import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Helmet from "react-helmet";

// Ленивая загрузка страниц
const Header = React.lazy(() => import("./components/Header"));
const Home = React.lazy(() => import("./pages/Home"));
const About = React.lazy(() => import("./pages/aboutPage"));
const Article = React.lazy(() => import("./pages/articlesPage"));
const Contact = React.lazy(() => import("./pages/contactsPage"));
const MainKompressori = React.lazy(() => import("./pages/kompressoriMain"));
const MainGeneratory = React.lazy(() => import("./pages/generatoryMain"));
const MainManipulatori = React.lazy(() => import("./pages/manipulatoriMain"));
const MainPodemniki = React.lazy(() => import("./pages/podemnikiMain"));
const MainSellTech = React.lazy(() => import("./pages/selltechMain"));
const Footer = React.lazy(() => import("./components/footer"));
const Contactsmini = React.lazy(() => import("./components/contactsmini"));
const PrivacyPolitic = React.lazy(() => import("./pages/privacypolitic"));
const QuestionsAnswers = React.lazy(() => import("./pages/QandA"));
const Notfound = React.lazy(() => import("./pages/notfound"));
const Remont = React.lazy(() => import("./pages/remont"));

// Статьи
const Article1 = React.lazy(() => import("./pages/subsarticles/Article1"));
const Article2 = React.lazy(() => import("./pages/subsarticles/Article2"));
const Article3 = React.lazy(() => import("./pages/subsarticles/Article3"));
const Article4 = React.lazy(() => import("./pages/subsarticles/Article4"));
const Article5 = React.lazy(() => import("./pages/subsarticles/Article5"));
const Article6 = React.lazy(() => import("./pages/subsarticles/Article6"));
const Article7 = React.lazy(() => import("./pages/subsarticles/Article7"));
const Article8 = React.lazy(() => import("./pages/subsarticles/Article8"));
const Article9 = React.lazy(() => import("./pages/subsarticles/Article9"));
const Article10 = React.lazy(() => import("./pages/subsarticles/Article10"));
const Article11 = React.lazy(() => import("./pages/subsarticles/Article11"));
const Article12 = React.lazy(() => import("./pages/subsarticles/Article12"));
const Article13 = React.lazy(() => import("./pages/subsarticles/Article13"));
const Article14 = React.lazy(() => import("./pages/subsarticles/Article14"));
const Article15 = React.lazy(() => import("./pages/subsarticles/Article15"));
const Article16 = React.lazy(() => import("./pages/subsarticles/Article16"));

// Генераторы
const Generator1 = React.lazy(() => import("./pages/generatory/generator1"));
const Generator2 = React.lazy(() => import("./pages/generatory/generator2"));
const Generator3 = React.lazy(() => import("./pages/generatory/generator3"));
const Generator4 = React.lazy(() => import("./pages/generatory/generator4"));
const Generator5 = React.lazy(() => import("./pages/generatory/generator5"));
const Generator6 = React.lazy(() => import("./pages/generatory/generator6"));
const Generator7 = React.lazy(() => import("./pages/generatory/generator7"));
const Generator8 = React.lazy(() => import("./pages/generatory/generator8"));
const Generator9 = React.lazy(() => import("./pages/generatory/generator9"));
const Generator10 = React.lazy(() => import("./pages/generatory/generator10"));
const Generator11 = React.lazy(() => import("./pages/generatory/generator11"));
const Generator12 = React.lazy(() => import("./pages/generatory/generator12"));

// Компрессоры
const Kompressor1 = React.lazy(() => import("./pages/kompressory/kompressor1"));
const Kompressor2 = React.lazy(() => import("./pages/kompressory/kompressor2"));
const Kompressor3 = React.lazy(() => import("./pages/kompressory/kompressor3"));
const Kompressor4 = React.lazy(() => import("./pages/kompressory/kompressor4"));
const Kompressor5 = React.lazy(() => import("./pages/kompressory/kompressor5"));
const Kompressor6 = React.lazy(() => import("./pages/kompressory/kompressor6"));
const Kompressor7 = React.lazy(() => import("./pages/kompressory/kompressor7"));
const Kompressor8 = React.lazy(() => import("./pages/kompressory/kompressor8"));

// Электрические компрессоры
const Podemnik1 = React.lazy(() => import("./pages/podemniky/podemnik1"));
const Podemnik2 = React.lazy(() => import("./pages/podemniky/podemnik2"));
const Podemnik3 = React.lazy(() => import("./pages/podemniky/podemnik3"));

// Манипуляторы
const Manipulator1 = React.lazy(() => import("./pages/manipilatory/manipulator1"));
const Manipulator2 = React.lazy(() => import("./pages/manipilatory/manipulator2"));

function App() {
  return (
    <BrowserRouter>
      <Helmet title="Аренда генераторов и компрессоров">
        <title>Аренда генераторов и компрессоров</title>
        
        <html lang="ru"></html>
      </Helmet>
      <Header />
      <div className="appdiv">
        <Suspense fallback={<div>Загрузка...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/articles" element={<Article />} />
            <Route path="/articles/Article1" element={<Article1 />} />
            <Route path="/articles/Article2" element={<Article2 />} />
            <Route path="/articles/Article3" element={<Article3 />} />
            <Route path="/articles/Article4" element={<Article4 />} />
            <Route path="/articles/Article5" element={<Article5 />} />
            <Route path="/articles/Article6" element={<Article6 />} />
            <Route path="/articles/Article7" element={<Article7 />} />
            <Route path="/articles/Article8" element={<Article8 />} />
            <Route path="/articles/Article9" element={<Article9 />} />
            <Route path="/articles/Article10" element={<Article10 />} />
            <Route path="/articles/Article11" element={<Article11 />} />
            <Route path="/articles/Article12" element={<Article12 />} />
            <Route path="/articles/Article13" element={<Article13 />} />
            <Route path="/articles/Article14" element={<Article14 />} />
            <Route path="/articles/Article15" element={<Article15 />} />
            <Route path="/articles/Article16" element={<Article16 />} />

            {/* Генераторы */}
            <Route path="/generators/AirmanSDG25S" element={<Generator1 />} />
            <Route path="/generators/DenyoDCA35" element={<Generator2 />} />
            <Route path="/generators/DenyoDCA45" element={<Generator3 />} />
            <Route path="/generators/AirmanSDG60S" element={<Generator4 />} />
            <Route path="/generators/DenyoDCA75" element={<Generator5 />} />
            <Route path="/generators/AirmanSDG100S" element={<Generator6 />} />
            <Route path="/generators/AirmanSDG150S" element={<Generator7 />} />
            <Route path="/generators/AirmanSDG220S" element={<Generator8 />} />
            <Route path="/generators/AirmanSDG300S" element={<Generator9 />} />
            <Route path="/generators/Wilson380E" element={<Generator10 />} />
            <Route path="/generators/WILSON700E" element={<Generator11 />} />
            <Route path="/generators/CumminsC500D5" element={<Generator12 />} />

            {/* Компрессоры */}
            <Route path="/disel-kompressors/AirmanPDS185SC" element={<Kompressor1 />} />
            <Route path="/disel-kompressors/AirmanPDS265SC" element={<Kompressor2 />} />
            <Route path="/disel-kompressors/AirmanPDS390SC" element={<Kompressor3 />} />
            <Route path="/disel-kompressors/AirmanPDS655S" element={<Kompressor4 />} />
            <Route path="/disel-kompressors/AirmanPDS400SAC" element={<Kompressor5 />} />
            <Route path="/disel-kompressors/Airman530S" element={<Kompressor6 />} />
            <Route path="/disel-kompressors/AirmanPDSF330DPC" element={<Kompressor7 />} />
            <Route path="/disel-kompressors/AirmanPDSF920S" element={<Kompressor8 />} />

            {/* Электрические компрессоры */}
            <Route path="/elek-kompressors/AirmanSMS22RD" element={<Podemnik1 />} />
            <Route path="/elek-kompressors/AirmanSMS37RD" element={<Podemnik2 />} />
            <Route path="/elek-kompressors/AirmanSMS75RD" element={<Podemnik3 />} />

            {/* Манипуляторы */}
            <Route path="/manipulators/MercedesBenzAtego1218" element={<Manipulator1 />} />
            <Route path="/manipulators/MANTGL" element={<Manipulator2 />} />

            {/* Страница контактов */}
            <Route path="/contacts" element={<Contact />} />

            <Route path="/generators" element={<MainGeneratory />} />
            <Route path="/disel-kompressors" element={<MainKompressori />} />
            <Route path="/manipulators" element={<MainManipulatori />} />
            <Route path="/elek-kompressors" element={<MainPodemniki />} />
            <Route path="/remont" element={<Remont />} />
            <Route path="/PrivacyPolitic" element={<PrivacyPolitic />} />
            <Route path="/questions" element={<QuestionsAnswers />} />
            <Route path="*" element={<Notfound />} />
          </Routes>
        </Suspense>
      </div>
      
      <Suspense fallback={<div>Загрузка контактов...</div>}>
        <Contactsmini />
      </Suspense>
      <Suspense fallback={<div>Загрузка футера...</div>}>
        <Footer />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
